import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import logo from '../assets/images/logo.png';
import { ReactComponent as Arrow } from '../assets/svg/Arrow-down.svg';
import { ReactComponent as Marker } from '../assets/svg/marker.svg';

function Header() {
  const [mobileMenu, toggleExpansion] = useState(false);
  const [carMenu, toggleCars] = useState(false);
  const [eventMenu, toggleEvent] = useState(false);

  function menuCars() {
    if (!eventMenu) {
    toggleCars(!carMenu);
    }
    else {
      toggleEvent(!eventMenu);
      toggleCars(!carMenu);
    }
  }

  function menuEvent() {
    if (!carMenu) {
      toggleEvent(!eventMenu);
      }
      else {
        toggleEvent(!eventMenu);
        toggleCars(!carMenu);
      }
  }

  return (
    <>
      <nav className="sticky top-0 z-50 nav--primary nav-bg">
        <div className="absolute md:max-w-screen-2xl inset-0 flex flex-wrap items-center justify-between xl:justify-start transition duration-[1000ms] delay-500 transform -translate-y-full opacity-0 boxed z-40">
          <div className="flex items-center flex-shrink-0 mr-24 text-white">
            <Link to="/">
              <span className="text-xl font-semibold tracking-tight text-white">
                <img src={logo} alt="PEAK MOTORS - Get your drive!" className="w-40 h-auto md:w-[240px] md:h-[40px]" />
              </span>
            </Link>
          </div>

          <div className="block xl:hidden">
            <button
              onClick={() => toggleExpansion(!mobileMenu)}
              className="flex items-center p-3 border border-white rounded-full text-primary-font hover:text-primary-font hover:border-primary-font"
            >
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div className="z-30 hidden w-full xl:flex xl:items-center xl:w-auto">
            <div className="mb-12 xl:flex-grow xl:mb-0 xl:space-x-6">
              <button onClick={() => menuCars()} className="navi-link hover:text-white">
                Sportwagen <Arrow className={`${carMenu ? `transform rotate-180` : ` `} w-5 fill-current text-white inline transition-transform duration-200`} />
              </button>
              <button onClick={() => menuEvent()} className="navi-link hover:text-white">
                Eventfahrzeuge <Arrow className={`${eventMenu ? `transform rotate-180` : ` `} w-5 fill-current text-white inline transition-transform duration-200`} />
              </button>
              <Link to={`/gutscheine`} className="navi-link">
                Gutscheine
              </Link>
              <Link to={`/ueber-uns`} className="navi-link">
                Über Uns
              </Link>
              <Link to={`/kontakt`} className="navi-link">
                Kontakt
              </Link>
            </div>

            <div className="absolute hidden right-12 top-6 lg:block">
              <Link to={`/kontakt`} className="inline-block transition-all duration-200 location-box">
                <span className="flex">
                  <Marker className="h-4 mt-1 mr-2" />
                  Walchsee, Tirol
                </span>
                <div className="absolute mt-2 text-sm transition-opacity duration-200 opacity-0 pointer-events-none">
                  Durchholzen 61
                  <br />
                  6344 Walchsee
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div
          className={`${carMenu ? `top-0` : `-top-72`} 
              w-full items-center absolute bg-menu bg-opacity-90 transition-all duration-500 z-30 h-72 text-center hidden xl:flex`}
        >
          <div className="inset-0 grid w-full grid-cols-2 gap-6 px-6 m-auto mb-12 lg:grid-cols-5 max-w-screen-2xl">
            <Link to={'/bmw-m3'} activeClassName="active" tabIndex={carMenu ? '0' : '-1'} aria-hidden={carMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 cursor-pointer hover:text-white opacity-80 hover:opacity-100">
                <StaticImage src="../assets/images/m3.png" placeholder="blurred" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-xl sm:text-lg">BMW M3 Competition</h3>
              </div>
            </Link>
            <Link to={'/bmw-m4'} activeClassName="active" tabIndex={carMenu ? '0' : '-1'} aria-hidden={carMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 cursor-pointer hover:text-white opacity-80 hover:opacity-100">
                <StaticImage src="../assets/images/m4.png" placeholder="blurred" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-xl sm:text-lg">BMW M4 Competition</h3>
              </div>
            </Link>
            <Link to={'/porsche-gt4'} activeClassName="active" tabIndex={carMenu ? '0' : '-1'} aria-hidden={carMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 cursor-pointer hover:text-white opacity-80 hover:opacity-100">
                <StaticImage src="../assets/images/gt4_neu.png" placeholder="blurred" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-xl sm:text-lg">Porsche Cayman GT4</h3>
              </div>
            </Link>
            <Link to={'/porsche-gt3'} activeClassName="active" tabIndex={carMenu ? '0' : '-1'} aria-hidden={carMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 cursor-pointer hover:text-white opacity-80 hover:opacity-100">
                <StaticImage src="../assets/images/gt3.png" placeholder="blurred" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-xl sm:text-lg">Porsche 911 GT3</h3>
              </div>
            </Link>
            <Link to={'/porsche-gts'} activeClassName="active" tabIndex={carMenu ? '0' : '-1'} aria-hidden={carMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 cursor-pointer hover:text-white opacity-80 hover:opacity-100">
                <StaticImage src="../assets/images/gts.png" placeholder="blurred" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-xl sm:text-lg">Porsche Taycan GTS</h3>
              </div>
            </Link>
          </div>
        </div>

        <div
          className={`${eventMenu ? `top-0` : `-top-72`} 
              w-full items-center absolute bg-menu bg-opacity-90 transition-all duration-500 z-30 h-72 text-center hidden xl:flex`}
        >
          <div className="inset-0 grid w-full grid-cols-2 gap-6 px-6 m-auto mb-12 lg:grid-cols-4 max-w-screen-2xl">
            <Link to={'/gmc-event-hummer'} activeClassName="active" tabIndex={carMenu ? '0' : '-1'} aria-hidden={carMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 cursor-pointer hover:text-white opacity-80 hover:opacity-100">
                <StaticImage src="../assets/images/hummer-thumb.png" placeholder="blurred" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-xl sm:text-lg">GMC Event Hummer H1</h3>
              </div>
            </Link>
          </div>
        </div>

        <div
          className={`${mobileMenu ? `right-0` : `right-full`} 
              w-full h-screen top-0 items-center absolute bg-menu bg-opacity-100 transition-all duration-500 z-30 text-center`}
        >
          <div className="inset-0 grid w-full grid-cols-2 gap-6 px-6 pt-24 m-auto">
            <Link to={'/bmw-m3'} activeClassName="active" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 opacity-100 cursor-pointer hover:text-white">
                <StaticImage src="../assets/images/m3.png" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-sm">BMW M3 Competition</h3>
              </div>
            </Link>
            <Link to={'/bmw-m4'} activeClassName="active" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 opacity-100 cursor-pointer hover:text-white">
                <StaticImage src="../assets/images/m4.png" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-sm">BMW M4 Competition</h3>
              </div>
            </Link>
            <Link to={'/porsche-gt4'} activeClassName="active" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 opacity-100 cursor-pointer hover:text-white">
                <StaticImage src="../assets/images/gt4_neu.png" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-sm">Porsche Cayman GT4</h3>
              </div>
            </Link>
            <Link to={'/porsche-gt3'} activeClassName="active" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 opacity-100 cursor-pointer hover:text-white">
                <StaticImage src="../assets/images/gt3.png" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-sm">Porsche 911 GT3</h3>
              </div>
            </Link>
            <Link to={'/porsche-gts'} activeClassName="active" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 opacity-100 cursor-pointer hover:text-white">
                <StaticImage src="../assets/images/gts.png" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-sm">Porsche Taycan GTS</h3>
              </div>
            </Link>
            <Link to={'/gmc-event-hummer'} activeClassName="active" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              <div className="col-span-1 text-center text-gray-400 transition duration-300 opacity-100 cursor-pointer hover:text-white">
                <StaticImage src="../assets/images/hummer-thumb.png" loading="eager" className="w-full sm:w-[240px]" alt="" />
                <h3 className="text-sm">GMC Event Hummer H1</h3>
              </div>
            </Link>
          </div>

          <div className="flex flex-col gap-4 p-6 pt-6 text-2xl text-center xl:hidden">
            <Link to={`/gutscheine`} className="navi-link" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              Gutscheine
            </Link>
            <Link to={`/ueber-uns`} className="navi-link" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              Über Uns
            </Link>
            <Link to={`/kontakt`} className="navi-link" tabIndex={mobileMenu ? '0' : '-1'} aria-hidden={mobileMenu ? 'false' : 'true'}>
              Kontakt
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
